import Vue from 'vue';
import 'aos/dist/aos.css'

// ===============Login helpers=============
import Admin from "../Login/Admin";
window.Admin = Admin;

// ===============Search===============
import Search from './../../components/elements/index/Search'
Vue.component('Search', Search)

// ===============Pagination===============
import Pagination from './../../components/elements/Pagination'
Vue.component('Pagination', Pagination)

// ===============VueLazyload===============
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
})

// ===============Flash Message===============
import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);

// ===============Spinner===============
import Spinner from './../../components/frontend/Spinner'
Vue.component('Spinner', Spinner)

// ===============Breadcrumbs from vuex===============
import breadcrumbs from "../../vuex/breadcrumbs_frontend";
window.frontBreadcrumbs = breadcrumbs;

import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
window.Validator = Validator;
Vue.use(SimpleVueValidation);

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);
import IconsPlugin from "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(IconsPlugin);

import VueSearchSelect from 'vue-search-select/dist/VueSearchSelect.css'
Vue.use(VueSearchSelect);

// ===============CKEditor===============
import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);
import CKEditorComponent from './../../components/backend/CKEditor'
Vue.component('editor', CKEditorComponent)

// jQuery Date Picker
import DatePicker from '../../components/elements/DatePicker.vue'
Vue.component('date-picker', DatePicker);
