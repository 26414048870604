<template>
  <!--main-->
  <main class="main">
    <!--company dashboard-->
    <div class="company-dashboard py-4" style="background: #f1f3fa">
      <div class="container-fluid">
        <div class="row">
          <!--===== left side start =====-->
          <div class="col-lg-3 col-xs-12">
            <left-side></left-side>
          </div>
          <!--===== left side end =====-->

          <!--===== other page start =====-->
          <div class="col-lg-9 col-xs-12 position-relative">
            <Loading v-if="spinner" />
            <router-view></router-view>
          </div>
          <!--===== other page end =====-->
        </div>
      </div>
    </div>
    <!--//company dashboard-->
  </main>
  <!--//main-->
</template>
<script>
import Loading from "./../../../components/frontend/Loading";
import leftSide from "./elements/LeftSide";
export default {
  name: "Layout",
  components: {
    leftSide,
    Loading,
  },
  data() {
    return {
      data: {},
      spinner: true,
      member: {
        info: null,
      },
      errors: {},
    };
  },
};
</script>
