Vue.mixin({
    methods: {
        // =================  get single data =================
        get_data(url, dataVar, paramsData = null) {
            //this.$root.spinner = true;
            axios.get("/" + url, { params: paramsData })
                .then(res => (this.datas[dataVar] = res.data))
                .catch(error => console.log(error))
                .then(alw => setTimeout(() => (this.$root.spinner = false), 200));
        },

        // =================  get paginate data =================
        get_paginate_data(url, params = null, allData = null) {
            axios
                .get(url, { params: params })
                .then(res => {
                    if (allData) {
                        this.extraData[allData] = res.data;
                    } else {
                        this.table['datas'] = res.data.data;
                        this.table['meta'] = res.data.meta;
                        this.table['links'] = res.data.links;
                    }
                })
                .catch(error => console.log(error))
                .then(alw => setTimeout(() => (this.$root.spinner = false), 200));
        },
    }
});