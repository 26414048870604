<template>
  <div class="h-97">
    <div class="card sidemenu shadow-sm mb-3 h-100" v-if="supplier.info">
      <div class="profile-sidebar">
        <div class="profile-userpic">
          <img v-if="supplier.info.cp_img" :src="supplier.info.cp_img" class="img-responsive" alt />
          <img v-else :src="$root.baseurl + '/images/user.png'" class="img-responsive" alt />
        </div>
        <div class="profile-usertitle">
          <div class="profile-usertitle-name">{{ supplier.info.name }}</div>
          <div class="profile-usertitle-job font-18">{{ supplier.info.supplierid }}</div>
        </div>
        <div class="profile-usermenu">
          <ul class="nav flex-column w-100 supplier-left-menu">
            <li class="nav-item">
              <router-link :to="{ name: 'dashboard' }" class="nav-link">Dashboard</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'profile' }" class="nav-link">Profile</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'supplierdocument.index' }" class="nav-link">Documents</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'quotations.index' }" class="nav-link">Quotation History</router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'supplier.openingTender' }"
                class="nav-link"
              >Tenders Opened Today</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'purchaseOrder.index' }" class="nav-link">Purchase Order</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'supplierbill.index' }" class="nav-link">Bill</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'supplier.message' }" class="nav-link">Message Board</router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'supplier.change_password' }"
                class="nav-link"
              >Change Password</router-link>
            </li>
            <li class="nav-item">
              <a style="cursor: pointer" class="nav-link" v-on:click="logout()">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <pre>{{supplier.info}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "LeftSide",
  data() {
    return {
      supplier: {
        info: {}
      }
    };
  },
  methods: {
    logout: function() {
      this.logoutSupplier();
    }
  },
  mounted() {
    this.loggedIn();
  }
};
</script>

<style >
.supplier-left-menu .nav-item .active {
  background: #aacde6;
}
</style>
