// ===============moment===============
import moment from 'moment'
var convertToBangla = require('number-translate-bangla');
// filter
Vue.filter('formatDate', function (value, format = 'll') {
    if (value) {
        moment.locale('en-gb');
        return moment(String(value)).format(format)
    }
});
Vue.filter('formatDateTime', function (value, format = 'llll') {
    if (value) {
        moment.locale('en-gb');
        return moment(String(value)).format(format)
    }
})
Vue.filter('banglaformatDate', function (value, format = 'LL') {
    if (value) {
        moment.locale('bn');
        return moment(String(value)).format(format)
    }
})
Vue.filter('remaining', function (date) {
    var date2 = moment(new Date());
    let date1 = moment(date);
    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');
    let rem = "";
    if (days > 0) {
        rem += days + ' days, ';
    }
    if (hours > 0) {
        rem += hours + ' hours, ';
    }
    if (minutes > 0) {
        rem += minutes + ' min';
    }
    return rem;
})
Vue.filter('currency', function (value) {
    if (value) {
        return new Number(value).toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 3 });
    }
})
Vue.filter('currencyNumber', function (value) {
    if (value) {
        return new Number(value).toLocaleString("en-IN", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
})
Vue.filter('bnCurrency', function (value) {
    if (value) {
        return new Number(value).toLocaleString("bn", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
})
Vue.filter('bnNumber', function (value) {
    if (value) {
        return new Number(value).toLocaleString("bn");
    }
})
Vue.filter('highlight', function (words, query) {
    return words.replace(query, '<a class="highlight">' + query + '</a>')
});
Vue.filter('prettify', function (value) {
    let data = value.split(":");
    let minutes = data[0];
    let secondes = data[1];
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (secondes < 10) {
        secondes = "0" + secondes;
    }
    return minutes + ":" + secondes;
});
Vue.filter('prettify_hours', function (value) {
    if (value) {
        let data = value.split(":");
        let hours = data[0];
        let minutes = data[1];
        let secondes = data[2];
        if (parseInt(hours) < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (secondes < 10) {
            secondes = "0" + secondes;
        }
        return hours + "h : " + minutes + "m : " + secondes + 's';

    }
});
// Vue.filter('toWords', function (value) {
//     if (!value) return '';
//     let number = value.split(".");
//     console.log(number[0]);
//     return converter.toWords(number[0]);
// })
Vue.filter('inWords', function (amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        let value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;
})
Vue.filter('banglaWords', function (n) {
    // let data = n.split(".");
    // if (data[1] == '00') {
    //     return convertToBangla(data[0]);
    // } else {
    return convertToBangla(n);
    // }

});
