<template>
  <div class="form-group" :class="getClass()">
    <slot name="label">
      <label :class="req ? 'control-label' : ''">{{ title }}</label>
    </slot>
    <input
      :id="id"
      type="text"
      :name="name"
      :value="value"
      readonly="readonly"
      :disabled="isDisabled"
      :placeholder="placeholder"
      :class="[{ 'dp-date-input': !isReadonly }, inputClass]"
      class="form-control form-control-sm date-input"
    />
    <span class="help-block">
      <slot name="help-block"> </slot>
    </span>
    <slot name="below"></slot>
  </div>
</template>

<script>
import "../../assets/frontend/datepicker/jquery-ui.css";
import "../../assets/frontend/datepicker/jquery-ui.js";

export default {
  props: {
    id: { type: String },
    value: { type: String, default: "" },
    title: { type: String, default: "" },
    col: { type: [String, Number], default: "3" },
    req: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isReadonly: { type: Boolean, default: false },
    dateFormat: { type: String, default: "dd-mm-yy" },
    changeMonth: { type: Boolean, default: true },
    changeYear: { type: Boolean, default: true },
    yearRange: { type: String, default: "-100:+0" },
    placeholder: { type: String, default: "Select Date" },
    name: { type: String, default: "" },
    inputClass: { type: Object, default: Object },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.isDisabled) {
          return this.$emit("input", "");
        }
      },
    },
  },

  mounted() {
    $(() => {
      $(`#${this.id}`).datepicker({
        showOn: "both",
        dateFormat: this.dateFormat,
        changeMonth: this.changeMonth,
        changeYear: this.changeYear,
        yearRange: this.yearRange,
        onSelect: (date, config) => this.$emit("input", date),
      });
    });
  },

  methods: {
    getClass() {
      let classes = null;
      let column = "col-" + String(this.col);
      classes = [column];
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.date-input,
.date-input:disabled {
  background: rgb(233 236 239);
  opacity: 1;
}
.dp-date-input {
  background: #fff;
}
</style>