import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// ========== define route========== 
import SupplierDashboard from './../views/frontend/Supplier/Layout';
const routes = [
    {
        path: '/', component: () => import('./../views/frontend/Layout'),
        // chlidren menu
        children: [
            { path: '/', name: 'home.page', component: () => import('./../views/frontend/Home') },
            // ------------------Content portion------------------
            { path: '/content/:slug', name: 'content', component: () => import('./../views/frontend/Content') },
            // ------------------Notice portion------------------
            { path: '/notices', name: 'home.Notice', component: () => import('./../views/frontend/Notice/Notice') },
            { path: '/notice-details/:slug', name: 'home.noticeDetails', component: () => import('./../views/frontend/Notice/NoticeDetails') },
            // ------------------News portion------------------
            { path: '/news', name: 'home.News', component: () => import('./../views/frontend/News/News') },
            { path: '/contact', name: 'contact', component: () => import('./../views/frontend/Contact') },
            { path: '/news-details/:slug', name: 'home.newsDetails', component: () => import('./../views/frontend/News/NewsDetails') },
            { path: '/gallery-detail/:album_id', name: 'photo_details', component: () => import('./../views/frontend/Album/AlbumDetails') },
            { path: '/photo-gallery/', name: 'photo_gallery', component: () => import('./../views/frontend/Album/Album') },
            { path: '/registration', name: 'registration', component: () => import('./../views/frontend/Registration/Supplier') },
            { path: '/forgot-password', name: 'forgotPassword', component: () => import('./../views/frontend/Login/ForgotPassword') },
            { path: '/reset-password/:id', name: 'resetPassword', component: () => import('./../views/frontend/Login/ResetPassword') },
            { path: '/authenticate', name: 'authenticate', component: () => import('./../views/login/Authenticate') },
            {
                path: '/supplier/',
                component: SupplierDashboard,
                children: [
                    { path: '/supplier/dashboard', name: 'dashboard', component: () => import('./../views/frontend/Supplier/Dashboard'), beforeEnter: authGuard },
                    { path: '/supplier/profile', name: 'profile', component: () => import('./../views/frontend/Supplier/Profile'), beforeEnter: authGuard },
                    { path: '/supplier/edit-profile', name: 'edit_profile', component: () => import('./../views/frontend/Supplier/ProfileEdit'), beforeEnter: authGuard },
                    { path: '/supplier/change-password', name: 'supplier.change_password', component: () => import('./../views/frontend/Supplier/ChangePassword'), beforeEnter: authGuard },
                    { path: '/supplier/supplierdocument', name: 'supplierdocument.index', component: () => import('./../views/frontend/Supplier//SupplierDocument/Index'), beforeEnter: authGuard },
                    { path: '/supplier/supplierdocument/create', name: 'supplierdocument.create', component: () => import('./../views/frontend/Supplier/SupplierDocument/Create'), beforeEnter: authGuard },
                    { path: '/supplier/supplierdocument/:id', name: 'supplierdocument.show', component: () => import('./../views/frontend/Supplier/SupplierDocument/View'), beforeEnter: authGuard },
                    { path: '/supplier/supplierdocument/:id/edit', name: 'supplierdocument.edit', component: () => import('./../views/frontend/Supplier/SupplierDocument/Create'), beforeEnter: authGuard },
                    { path: '/supplier/quotations', name: 'quotations.index', component: () => import('./../views/frontend/Supplier/Quotations/Index'), beforeEnter: authGuard },
                    { path: '/supplier/quotations/:id', name: 'quotations.show', component: () => import('./../views/frontend/Supplier/Quotations/View'), beforeEnter: authGuard },
                    { path: '/supplier/opening-tender', name: 'supplier.openingTender', component: () => import('./../views/frontend/Supplier/OpenTender'), beforeEnter: authGuard },
                    { path: '/supplier/purchase-order', name: 'purchaseOrder.index', component: () => import('./../views/frontend/Supplier/PurchaseOrder/Index'), beforeEnter: authGuard },
                    { path: '/supplier/purchase-order/:id', name: 'purchaseOrder.show', component: () => import('./../views/frontend/Supplier/PurchaseOrder/View'), beforeEnter: authGuard },
                    { path: '/supplier/all-notification', name: 'allNotification', component: () => import('./../views/frontend/Supplier/Notification'), beforeEnter: authGuard },
                    { path: '/supplier/bill', name: 'supplierbill.index', component: () => import('./../views/frontend/Supplier/Bill/Index'), beforeEnter: authGuard },
                    { path: '/supplier/bill/create', name: 'preparebill', component: () => import('./../views/frontend/Supplier/Bill/Create'), beforeEnter: authGuard },
                    { path: '/supplier/bill/show/:id', name: 'supplierbill.view', component: () => import('./../views/frontend/Supplier/Bill/View'), beforeEnter: authGuard },
                    { path: '/supplier/message', name: 'supplier.message', component: () => import('./../views/frontend/Supplier/Message/Create'), beforeEnter: authGuard },
                    { path: '/supplier/message/:id', name: 'supplier.message.show', component: () => import('./../views/frontend/Supplier/Message/View'), beforeEnter: authGuard },
                ]
            },
            { path: '/supplier/tender-apply/:id', name: 'quotations.apply_online', component: () => import('./../views/frontend/Supplier/Quotations/Create') },
            { path: '/supplier/quotations/:id/edit', name: 'quotations.edit', component: () => import('./../views/frontend/Supplier/Quotations/Edit') },

            // ------------------Notice portion------------------
            { path: '/tenders', name: 'home.tender', component: () => import('./../views/frontend/Tender/TenderCategory') },
            { path: '/tenders/:slug', name: 'home.tenderCategory', component: () => import('./../views/frontend/Tender/TenderCategory') },
            { path: '/tender/:id', name: 'home.tenderDetails', component: () => import('./../views/frontend/Tender/TenderDetails') },
            { path: '/suppliers', name: 'home.suppliers', component: () => import('./../views/frontend/Suppliers/SupplierList') },


            /*------SSL Payment Status--------*/
            { path: '/payment-success', component: () => import('./../views/frontend/SSL/Success') },
            { path: '/ssl-payment-cancel', component: () => import('./../views/frontend/SSL/Cancel') },
            { path: '/ssl-payment-failed', component: () => import('./../views/frontend/SSL/Failed') }
        ],
    }
]

// ========== define router==========
const router = new VueRouter({
    routes,
    hashbang: false,
    mode: 'history',
    base: process.env.MIX_VUE_ROUTER_BASE,
    linkActiveClass: "active",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },

})

// ==========check login or not==========
function authGuard(to, from, next) {
    let baseURL = document.head.querySelector('meta[name="base-url"]').content;
    axios.get('/supplier/loggedOrNot/suppliers')
        .then(response => {
            if (!response.data) {
                window.location = baseURL + '/authenticate'
            } else {
                next();
            }
        })
}


router.beforeEach((to, from, next) => {
    // console.log(from);
    // console.log(to);

    next();
    // active parent menu
    setTimeout(() => {
        $('.nav-item').children('.nav-link').removeClass('parent-active')
        $('.router-link-exact-active').parents('.dropdown-menu').siblings('#navbarDropdown').addClass('parent-active');
    }, 100);
})


export default router
